import { Outlet } from 'react-router-dom';
import CustomerList from '../pages/customers/CustomerList';
import AddCustomer from '../pages/customers/AddCustomer';


export const OperatorRoutes = [
    {
        title: "Customers", element: <Outlet />, name: "Customers", children: [
            { title: "All Customer", path: 'customers/list', element: <CustomerList />, name: "Customers" },
            { title: "Add Customer", path: 'customers/add', element: <AddCustomer />, name: "Add Customer" },
        ]
    },
    // { title:"Add Customer",path: 'customers/add', element: <AddCustomer /> ,name:"Add Customer"},
    // { title: "Profile", path: 'profile', element: <Profile />, name: "Profile" },
]
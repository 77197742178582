
import CustomerList from '../pages/customers/CustomerList';
import TransactionList from '../pages/transactions/TransactionList';

export const OrgAdminRoutes = [
    {path: 'customers/list', element: <CustomerList/>,name:"My Customers",title:"My Customers"},
    {path: 'transactions/list', element: <TransactionList/>,name:"Transactions",title:"My Org Transactions"},
    // {
    //     title: "Card Management",
    //     element: <Outlet />,
    //     icon:"mdi:credit-card-multiple-outline",
    //     name: "Card Managements",
    //     children: [
    //         { path: 'card-management/requests',icon:"carbon:request-quote", title: "Card Requests", element: <CardRequests />, name: "Card Requests" }
    //     ]
    // }
]
import * as Yup from 'yup';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// form
import { useFormik } from 'formik';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Box, Tooltip, Zoom, Checkbox, FormControlLabel,FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';
import { alpha,useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { PUBLIC_API, PUBLIC_FORM_API, TOKEN, USER_GROUPS } from '../../../Config';

// ----------------------------------------------------------------------

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 42,
    height: 42,
    border: `2px solid ${theme.palette.background.paper}`,
    background: theme.palette.primary.darker,
    cursor: "pointer"
}));

export default function RegisterForm(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const [image, setImage] = useState(null);
    const [phone, setPhone] = useState();
    const pictureElement = useRef(null);
    const hiddenFileInput = React.useRef(null);
    const [timer, setTimer] = useState(0)
    const [showTimer, setShowTimer] = useState(false)
    const navigate = useNavigate()
    const theme = useTheme()

    function toClear() {
        // pictureElement.current.clearPictures();
    }
    const handlePhotoChangeClick = event => {
        // setShowPhotoDialog(true)
        hiddenFileInput.current.click();
    };
    const handlePhotoChange = event => {
        const fileUploaded = event.target.files[0];
        if (validatePhotoSize(fileUploaded)) {
            setImage(fileUploaded)
        }
        else {
            console.log("large file")
            enqueueSnackbar("Image size limit exceed (max:5MB)", {
                variant: "warning",
            });
        }
    };

    const validatePhotoSize = (file) => {
        const MAX_FILE_SIZE = 1024 // 5MB
        if (!file) {
            return false
        }
        const fileSizeKiloBytes = file.size / 1024
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            return false
        }
        return true
    }

    const RegisterSchema = Yup.object().shape({
        first_name: Yup.string().required('First name required'),
        last_name: Yup.string(),
        email: Yup.string().required().email('Email must be a valid email address'),
        phone: Yup.string().required('Phone is required').min(11).max(11),
        // contact_phone: Yup.string().min(11).max(11),
        password: Yup.string().required('Phone is required').min(8).max(100),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
        verification_code: Yup.string().required('Code is required').min(6).max(6),
        terms: Yup.boolean().required("The terms and conditions must be accepted.").oneOf([true], "The terms and conditions must be accepted.")
    });

    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        // contact_phone:'',
        password:'',
        confirm_password:'',
        verification_code:'',
        terms:false,
    };

    const twoPageRegister = async (values, { setSubmitting, setErrors, resetForm }) => {
        console.log(values)
        const formData = new FormData()
        Object.entries(values).forEach((item, idx) => {
            console.log(item)
            if (item[1] !== null && item[1] !== undefined) {
                formData.append(item[0], item[1])
            }
        })

        if (image !== null) {
            sessionStorage.setItem("reg_photo", image)
        }
        // sending OTP to user phone and redirecting to verify otp page
        sessionStorage.setItem("reg_data", JSON.stringify(values))
        await PUBLIC_API.post('auth/user/otp/send/', { phone: regForm.values.phone, email: regForm.values.email }).then(res => {
            console.log(res.data)
            setShowTimer(true)
            setSendingOTP(false)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
            navigate('/verify-otp', { replace: true, state: { photo: image } });
        }).catch(err => {
            const errors = {}
            Object.entries(err.response.data.errors).forEach(([key,val],idx)=>{
                errors[key]=val[0]
            })
            console.log('errors',errors)
            setErrors(errors)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
        })

    };

    const register = async (values, { setSubmitting, setErrors, resetForm }) => {
        const formData = new FormData()
        // const regData = JSON.parse(sessionStorage.getItem("reg_data"))
        Object.entries(values).forEach((item, idx) => {
            if (item[1] !== null && item[1] !== undefined) {
                formData.append(item[0], item[1])
            }
        })
        if (image !==null) {
            formData.append("profile_pic", image);
        }
        await PUBLIC_FORM_API.post('auth/registration/', formData).then(async res => {
            console.log('reg data',res.data)
            resetForm()
            enqueueSnackbar('Self registration completed', {
                variant: "success",
            });
            await login({ email_or_phone: String(values.email).length>0?values.email:values.phone, password: values.password }, setSubmitting)
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response?.data?.error_detail)
            if (err.response?.data?.errors) {
                setErrors(err.response.data.errors)
            }
            enqueueSnackbar(err.response.data?.message, {
                variant: "warning",
            });
        })
    };

    const login = async (loginData, setSubmitting) => {
        await PUBLIC_API.post("auth/login/email/", loginData).then(async loginRes => {
            console.log("loginRes", loginRes.data.success === true)
            if (loginRes.data?.success === true) {
                localStorage.setItem(TOKEN, loginRes.data.token)
                localStorage.setItem(USER_GROUPS, JSON.stringify(loginRes.data.data.groups))
                // setOTPVerified(true)
                setSubmitting(false)
                navigate('/dashboard/how-to', { replace: true });
            }
        }).catch(err => {
            console.log(err.response?.data)
            setSubmitting(false)
            enqueueSnackbar(err.response.data?.message, {
                variant: "warning",
            });
        })
    }

    const regForm = useFormik({
        initialValues: defaultValues,
        validationSchema: RegisterSchema,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: register
    })

    const onDrop = (picture) => {
        setImage(picture);
    };
    React.useEffect(() => {
        let timeout = null

        if (showTimer) {
            timeout = setTimeout(() => {
                setShowTimer(false)
            }, 60000);
        }
        return () => clearTimeout(timeout)
    }, [showTimer]);

    const [sendingOTP, setSendingOTP] = useState(false)
    const sendOTP = () => {
        setSendingOTP(true)
        PUBLIC_API.post('auth/user/otp/send/', { phone: regForm.values.phone, email: regForm.values.email }).then(res => {
            console.log(res.data)
            setShowTimer(true)
            setSendingOTP(false)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            console.log(err.response.data.message)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
            if (err.response?.data?.errors) {
                regForm.setErrors(err.response.data.errors)
            }
            setSendingOTP(false)
        })
    }

    const OTPButton = () => (
        <LoadingButton sx={{mb:1}} size='small' type='button' variant='contained' onClick={sendOTP} loading={sendingOTP} disabled={showTimer}>{showTimer === false ? 'Get Code' : 'Try after a minute'}</LoadingButton>
    )
    const TermsText = ()=>(
        <div>
            <span>I Agree to the </span>
            <Link style={{}}>Terms and Conditions</Link>
        </div>
    )
    const handleOnChange = (value, data, event, formattedValue) => {
        setPhone(value)
        regForm.setFieldValue('phone', "+".concat(String(value)))
        // this.setState({ rawPhone: value.slice(data.dialCode.length) })
    }

    return (
        <>
            <Box>
                <Stack spacing={1}>
                    <Stack direction={{ xs: 'column', sm: 'row', md: 'column' }} spacing={2}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <Tooltip title="Add Photo" arrow TransitionComponent={Zoom} placement="right">
                                    <SmallAvatar onClick={handlePhotoChangeClick} alt="Remy Sharp" src="/static/images/avatar/1.jpg">
                                        <Iconify sx={{ borderRadius: 20, }} icon={"line-md:plus"} />
                                    </SmallAvatar>
                                </Tooltip>
                            }
                            sx={{ mb: 2, mt: 2, alignSelf: "center" }}
                        >
                            <Avatar
                                alt={"profile_photo"}
                                src={image ? URL.createObjectURL(image) : String(process.env.PUBLIC_URL).concat("/static/images/unisex-avatar.webp")}
                                sx={(theme) => ({
                                    width: 100, height: 100, border: "solid"
                                })}
                            />
                        </Badge>
                        <input type={"file"} style={{ display: "none" }} ref={hiddenFileInput} onChange={handlePhotoChange} accept="image/png, image/jpeg" />
                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                fullWidth
                                variant="standard"
                                required
                                size='small'
                                id="first_name"
                                name="first_name"
                                value={regForm.values.first_name}
                                onChange={regForm.handleChange}
                                error={!!regForm.errors.first_name && regForm.touched.first_name}
                                helperText={regForm.touched.first_name?regForm.errors?.first_name:undefined}
                                placeholder="type your first name"
                                label="First Name"
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                size='small'
                                id="last_name"
                                name="last_name"
                                value={regForm.values.last_name}
                                onChange={regForm.handleChange}
                                error={!!regForm.errors.last_name && regForm.touched.last_name}
                                helperText={regForm.touched.last_name? regForm.errors?.last_name:undefined}
                                placeholder="type your last name"
                                label="Last Name"
                            />
                        </Stack>
                    </Stack>
                    <FormControl>
                        <TextField
                            fullWidth
                            required
                            variant="standard"
                            size='small'
                            id="email"
                            name="email"
                            value={regForm.values.email}
                            onChange={regForm.handleChange}
                            error={regForm.errors.email && regForm.touched.email}
                            helperText={regForm.touched.email? regForm.errors?.email:undefined}
                            placeholder="type your email"
                            label="Email"
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            fullWidth
                            variant="standard"
                            required
                            size='small'
                            id="phone"
                            name="phone"
                            value={regForm.values.phone}
                            onChange={regForm.handleChange}
                            error={(regForm.errors.phone_number || regForm.errors?.phone) && regForm.touched.phone}
                            helperText={regForm.touched.phone? (regForm.errors?.phone_number || regForm.errors?.phone):undefined}
                            placeholder="type your bKash phone number"
                            label="bKash Account Number"
                        />
                    </FormControl>
                    {/* <FormControl>
                        <TextField
                            fullWidth
                            variant="standard"
                            size='small'
                            id="contact_phone"
                            name="contact_phone"
                            value={regForm.values.contact_phone}
                            onChange={regForm.handleChange}
                            error={regForm.errors?.contact_phone}
                            helperText={regForm.errors?.contact_phone}
                            placeholder="type your contact number"
                            label="Contact Number"
                        />
                    </FormControl> */}
                    <FormControl>
                        <TextField
                            fullWidth
                            variant='standard'
                            type="number"
                            required
                            id="verification_code"
                            name="verification_code"
                            value={regForm.values.verification_code}
                            onChange={regForm.handleChange}
                            error={regForm.errors.verification_code && regForm.touched.verification_code}
                            helperText={regForm.touched.verification_code ? regForm.errors?.verification_code:undefined}
                            placeholder="type code"
                            label="Verification Code"
                            InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <OTPButton />
                                </InputAdornment>
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            fullWidth
                            variant="standard"
                            required
                            id="password"
                            name="password"
                            size='small'
                            value={regForm.values.password}
                            onChange={regForm.handleChange}
                            error={!!regForm.errors.password && regForm.touched.password}
                            helperText={regForm.touched.password? regForm.errors?.password:undefined}
                            label="Set Password"
                            placeholder='Type your password'
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            fullWidth
                            variant="standard"
                            id="confirm_password"
                            name="confirm_password"
                            size='small'
                            required
                            value={regForm.values.confirm_password}
                            onChange={regForm.handleChange}
                            error={!!regForm.errors.confirm_password && regForm.touched.confirm_password}
                            helperText={regForm.touched.confirm_password?regForm.errors?.confirm_password:undefined}
                            label="Confirm Password"
                            placeholder='Type your password'
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel label={<TermsText/>} disabled={regForm.isSubmitting} control={
                            <Checkbox 
                                required
                                id='terms'
                                name="terms"
                                label={'*'}
                                value={regForm.values.terms} 
                                error={!!regForm.errors.terms && regForm.touched.terms}
                                onChange={regForm.handleChange}
                                color="default" 
                                size="small" 
                                sx={{ 
                                    '& .MuiSvgIcon-root': { fontSize: 15 }, 
                                    '&.Mui-checked': {
                                        color: (theme) => alpha(theme.palette.primary.main, 0.8),
                                    },
                                }}
                            />
                        }/>
                        {regForm.touched.terms && regForm.errors.terms && <FormHelperText sx={{color:'red'}}>{regForm.errors.terms}</FormHelperText>}
                    </FormControl>
                    <LoadingButton fullWidth size="small" type="button" onClick={regForm.handleSubmit} variant="contained" loading={regForm.isSubmitting}>
                        Register
                    </LoadingButton>
                </Stack>

            </Box>
        </>
    );
}

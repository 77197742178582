import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material

import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import { styled } from '@mui/system';

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import BkashLogo from '../../components/BkashLogo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { customerNavConfig, operatorNavConfig, orgAdminNavConfig } from './Navigations';
import { capitalizeFirstLetter, hasGroup } from '../../helper';
import CustomNavSection from '../../components/CustomNavSection';
import Iconify from '../../components/Iconify';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const myDetail = useSelector(state => state.myDetails.data)

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const getNavigations=()=>{
        let config=[]
        if(hasGroup('User')){
            config= customerNavConfig
        }
        else if(hasGroup('Collector')){
            config= operatorNavConfig
        }
        else if(hasGroup('Organization Admin')){
            config= orgAdminNavConfig
        }
        return config
    }

    const renderContent = (
        <Scrollbar
            sx={{ height: 1, '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }, }}
        >
            <Box sx={{ pl: 10, py: 3, pr: 10, display: 'inline-flex' }}>
                <BkashLogo />
            </Box>

            <Box sx={{ mb: 5, mx: 2.5 }}>
                <Link underline="none" component={RouterLink} to="#">
                    <AccountStyle>
                        <Avatar src={myDetail.profile_pic?myDetail.profile_pic:String(process.env.PUBLIC_URL).concat("/static/images/unisex-avatar.png")} alt="photoURL" sx={{border:'2px solid'}}/>
                        {/* <Iconify icon={"carbon:user-avatar-filled-alt"} height="500" width="500"/> */}
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                {`${capitalizeFirstLetter(myDetail.first_name)} ${myDetail.last_name}`}
                            </Typography>
                            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {account.role}
                            </Typography> */}
                        </Box>
                    </AccountStyle>
                </Link>
            </Box>

            <CustomNavSection navConfig={getNavigations()} />

            <Box sx={{ flexGrow: 1 }} />

            {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                    <Box
                        component="img"
                        src={String(process.env.PUBLIC_URL).concat("/static/illustrations/illustration_avatar.png")}
                        sx={{ width: 100, position: 'absolute', top: -50 }}
                    />

                    <Box sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant="h6">
                            Found interesting?
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            We are comming soon
                        </Typography>
                    </Box>
                </Stack>
            </Box> */}
            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                    {/* <Box
                        component="img"
                        src={String(process.env.PUBLIC_URL).concat("/static/illustrations/illustration_avatar.png")}
                        sx={{ width: 100, position: 'absolute', top: -50 }}
                    /> */}

                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                            {'Copyright © '}
                            <Link color="inherit" href="https://dma.com.bd/">
                                DMA
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            We are comming soon
                        </Typography> */}
                    </Box>
                </Stack>
            </Box>
        </Scrollbar>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}

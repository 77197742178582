export const API_BASE_URLS={
    'dev':'https://sandbox.dma-bd.com/bkashcard/cms/',
    'prod':'https://bkashcard.dma-bd.com/cms/',
    'dev_local':'http://localhost:8000/cms/'
}

export const MEDIA_BASE_URLS={
    'dev':'https://sandbox.dma-bd.com/bkashcard/cms',
    'prod':'https://bkashcard.dma-bd.com/cms',
    'dev_local':'http://localhost:8000/cms'
}
import { useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Link, Button, Card, TextField, Container, Stack, Typography, FormControl, InputAdornment, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from "notistack";
import { useNavigate,Link as RouterLink } from "react-router-dom";
import { PUBLIC_API } from "../../Config";
import useResponsive from "../../hooks/useResponsive";
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';


const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));
const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // padding: theme.spacing(12, 0),
}));

const ForgetPassword = () => {
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [otpSent, setOtpSent] = useState(false)

    const getOTP = (values, { setSubmitting, setErrors, resetForm }) => {
        PUBLIC_API.post('auth/user/forgot/password/', values).then(res => {
            setSubmitting(false)
            console.log(res.data)
            if (res.data.success === true) {
                setOtpSent(true)
                form2.setFieldValue('email_or_phone', values.email_or_phone)
                enqueueSnackbar(res.data.message, {
                    variant: "success",
                });
            }
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response)
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
        })
    }
    const setPassword = (values, { setSubmitting, setErrors, resetForm }) => {
        PUBLIC_API.post('auth/user/forgot/password/set/', values).then(res => {
            setSubmitting(false)
            console.log(res.data)
            if (res.data.success === true) {
                navigate('/login', { state: { message: 'Your new password has been set', type: "success" } })
            }
        }).catch(err => {
            setSubmitting(false)
            console.log(err.response)
        })
    }
    const form1 = useFormik({
        initialValues: { email_or_phone: '' },
        validationSchema: Yup.object().shape({ email_or_phone: Yup.string().required('Email or Phone required'), }),
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: getOTP
    })
    const form2 = useFormik({
        initialValues: { otp: '', email_or_phone: '', password: '', password_confirm: '' },
        validationSchema: Yup.object().shape({
            email_or_phone: Yup.string().required('Email or Phone required'),
            otp: Yup.string().required('Code required'),
            password: Yup.string().min(8).required('Password required'),
            password_confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: setPassword
    })
    return (
        <>
            <Page title="Forgot Password">
                <RootStyle>
                    <Container maxWidth="sm">
                        <ContentStyle>
                            <Grid>
                                <Grid item mdOffset={3} md={6} sm={6} xs={12}>
                                    <Typography variant="h4">
                                        Forgot Password
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        {!otpSent ? "Please type your registered email or bKash account number to get the verification code" : "Please submit verification code and set your new password"}
                                    </Typography>

                                    {!otpSent ? (<Stack sx={{ mt: 3 }} direction={{ xs: 'column', sm: 'column', md: 'column' }} spacing={2}>
                                        <FormControl size="small">
                                            <TextField
                                                fullWidth
                                                required
                                                variant="standard"
                                                id="email_or_phone"
                                                name="email_or_phone"
                                                value={form1.values.email_or_phone}
                                                onChange={form1.handleChange}
                                                error={!!form1.errors.email_or_phone}
                                                helperText={form1.errors?.email_or_phone}
                                                placeholder="Enter your email or phone number"
                                                label="Registered email or bKash account number"
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') form1.handleSubmit()
                                                }}
                                            />
                                        </FormControl>
                                        <LoadingButton fullWidth size="medium" disabled={!form1.isValid} type="button" onClick={form1.handleSubmit} variant="contained" loading={form1.isSubmitting}>
                                        Get Verification Code
                                        </LoadingButton>
                                    </Stack>) :
                                        (<Stack sx={{ mt: 3 }} direction={{ xs: 'column', sm: 'column', md: 'column' }} spacing={2}>
                                            <FormControl size="small">
                                                <TextField
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    id="otp"
                                                    name="otp"
                                                    value={form2.values.otp}
                                                    onChange={form2.handleChange}
                                                    error={!!form2.errors.otp}
                                                    helperText={form2.errors?.otp}
                                                    placeholder="Enter 6 digit verification code"
                                                    label="Verification Code"
                                                />
                                            </FormControl>
                                            <FormControl size="small">
                                                <TextField
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    id="password"
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={form2.values.password}
                                                    onChange={form2.handleChange}
                                                    error={!!form2.errors.password}
                                                    helperText={form2.errors?.password}
                                                    placeholder="Enter new 8 digit password"
                                                    label="Password"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') form2.handleSubmit()
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl size="small">
                                                <TextField
                                                    fullWidth
                                                    required
                                                    variant="standard"
                                                    id="password_confirm"
                                                    name="password_confirm"
                                                    type={showPasswordConfirm ? 'text' : 'password'}
                                                    value={form2.values.password_confirm}
                                                    onChange={form2.handleChange}
                                                    error={!!form2.errors.password_confirm}
                                                    helperText={form2.errors?.password_confirm}
                                                    placeholder="Type your password again"
                                                    label="Confirm Password"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                                                                    <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') form2.handleSubmit()
                                                    }}
                                                />
                                            </FormControl>
                                            <LoadingButton fullWidth size="medium" disabled={!form2.isValid} type="button" onClick={form2.handleSubmit} variant="contained" loading={form2.isSubmitting}>
                                                Submit
                                            </LoadingButton>
                                        </Stack>)}
                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                                        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/">
                                            Go to Login
                                        </Link>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </ContentStyle>
                    </Container>
                </RootStyle>
            </Page>
        </>
    )
}

export default ForgetPassword
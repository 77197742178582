import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterNApply from './pages/regNapply/RegisterNApply';
// import OperatorHome from './pages/OperatorHome';
import { TOKEN } from './Config';
import { hasGroup } from './helper';
import { CustomerRoutes } from './routes/CustomerRoutes';
import { OperatorRoutes } from './routes/OperatorRoutes';
import { OrgAdminRoutes } from './routes/OrgAdminRoutes';
// import Profile from './pages/profile/Profile';
import CustomerProfile from './pages/profile/CustomerProfile';
// import NotFound from './pages/Page404';
import ForgetPassword from './pages/forgot-pass/ForgotPassword';
import BkashCallback from './pages/BkashCallback';
import VerifyOTP from './pages/verify-otp/VerifyOTP';
import VerifyNAddress from './pages/regNapply/VerifyNAddress';

// import TestCom from './pages/test/TestCom'

// const MerchantLogin = React.lazy(()=>import('./pages/MerchantLogin'))
// const CustomerDashboard = React.lazy(()=>import('./pages/dashboard/CustomerDashboard'))

// ----------------------------------------------------------------------
const baseRedirect = ()=>{
    let path = ''
    if(localStorage.getItem(TOKEN)){
        if(hasGroup('User')) path = "/dashboard/how-to";
        else if(hasGroup('Organization Admin')) path = "/dashboard/transactions/list";
    }
    else{
        path = "/login";
    }
    return path;
}
export const children = () => {
    let dashboardChildrens = [
        // { path: 'app', element: <CustomerDashboard />, name: "Dashboard", title: "Dashboard", icon: "mdi:view-dashboard" },
        { title: "Profile", path: 'profile', element: <CustomerProfile />, name: "Profile", icon: "mdi:account" },
    ]
    if (hasGroup('User') && hasGroup('Collector') && hasGroup('Organization Admin')) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(CustomerRoutes).concat(OperatorRoutes).concat(OrgAdminRoutes);
    }
    else if (hasGroup('User')) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(CustomerRoutes);
    }
    else if (hasGroup('Collector')) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(OperatorRoutes);
    }
    else if (hasGroup('Organization Admin')) {
        dashboardChildrens = Array.from(dashboardChildrens).concat(OrgAdminRoutes);
    }
    console.log('routes',dashboardChildrens)
    return dashboardChildrens
}
export default function Router() {
    // const isAuthenticated = localStorage.getItem(TOKEN)?true:false
    return useRoutes([
        {
            path: '/',
            // element: <LogoOnlyLayout />,
            element: <Navigate to={baseRedirect()} />,
            // children: [
            //   { path: '/', element: <Navigate to={localStorage.getItem(TOKEN) ? "/dashboard/app" : "/login"}/> },
            //   { path: '404', element: <NotFound /> },
            //   { path: '*', element: <Navigate to="/404" /> },
            // ],
        },
        {
            path: 'login',
            element: <Login />,
        },
        // {
        //   path: 'test',
        //   element: <TestCom />,
        // },
        {
            path: 'register',
            element: <Register />,
        },
        {
            path: 'register-apply/:paramStr',
            element: <RegisterNApply />,
        },
        {
            path: 'verify-address',
            element: <VerifyNAddress />,
        },
        {
            path: 'verify-otp',
            element: <VerifyOTP />,
        },
        {
            path: 'forget-pass',
            element: <ForgetPassword />,
        },

        {
            path: 'dashboard',
            element: <DashboardLayout />,
            children: [...children()],
        },
        {
            path: '*',
            element: <Navigate to="/" />,
        },
    ]);
}

import * as Yup from 'yup';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import {
    Stack, IconButton, InputAdornment, TextField,
    Container, Button,
    Typography, Box, FormControl, Card, CardContent, CardMedia, Zoom
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import LoaderDialog from '../../components/LoaderDialog';
import { API } from '../../Config';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { fetchMyCard } from '../../store/slices/MyCardSlice';
import useResponsive from '../../hooks/useResponsive';

// ---------------------------------------------------------------------

const CardNumberStyle = styled('span')(({ theme }) => ({
    position: 'absolute',
    color: 'black',
    fontWeight: 'bold',
    bottom: '45px',
    left: '6%',
    [theme.breakpoints.down('sm')]: {
        fontSize: "18px",
    },
    [theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
        fontSize: "28px",
    },
    [theme.breakpoints.up('md')]: {
        fontSize: "26px",
    },
}));

const OwnerNameStyle = styled('span')(({ theme }) => ({
    position: 'absolute',
    color: 'black',
    fontWeight: 'bold',
    bottom: '20px',
    left: '6%',
    fontSize: "18px",
    [theme.breakpoints.down('sm')]: {
        fontSize: "16px",
    },
    // [theme.breakpoints.down('md') && theme.breakpoints.up('sm')]: {
    //     fontSize:"18px",
    // },
    // [theme.breakpoints.up('md')]: {
    //     fontSize:"18px",
    // },
}));

export default function ActivatePrePrintedCard() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const smUp = useResponsive('up', 'sm');
    const [showOTP, setShowOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false)
    const [url, setUrl] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const myCard = useSelector(state => state.myCard.data)
    const hasCard = useSelector(state => state.myCard.has_card)
    const systemConfig = useSelector(state => state.systemConfig.data)
    const [showWaitDialog, setShowWaitDialog] = useState(false)
    const [showTimer, setShowTimer] = useState(false);
    const [sendingOTP, setSendingOTP] = useState(false);
    const ValidationSchema = Yup.object().shape({
        digits: Yup.string().required('Last 3 digits are required').max(3).min(3),
        otp: Yup.string().required('Verification code is required').max(6).min(6),
    });
    const activate = (values, { setSubmitting, setErrors, resetForm }) => {
        API.post('nfccards/activate/otp/', values).then(res => {
            console.log('active result', res.data)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
            setSubmitting(false)
            if (res.data.data?.url) {
                setShowWaitDialog(true)
                setUrl(res.data.data.url)
            }
            else {
                navigate('/dashboard/my-card', { replace: true });
            }
        }).catch(err => {
            enqueueSnackbar(err.response.data.message, {
                variant: "error",
            });
            setSubmitting(false)
            // activationForm.setErrors(err.response)
        })
    }
    const activationForm = useFormik({
        initialValues: {
            otp: '',
            digits: ''
        },
        validationSchema: ValidationSchema,
        validateOnBlur: false,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: activate
    })

    React.useEffect(() => {
        console.log('loc', location.state)
        // if(!location.state?.card_activation){
        //     navigate('/dashboard/my-card')
        // }
    }, [])
    const onEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            activationForm.handleSubmit()
        }
    }

    const getActivationOTP = async () => {
        setSendingOTP(true);
        await API.get('nfccards/activate/scan/'.concat(activationForm.values.digits).concat("/")).then(res => {
            setOtpSent(true)
            enqueueSnackbar(res.data.message, {
                variant: "success",
            });
        }).catch(err => {
            enqueueSnackbar(err.response.data.message, {
                variant: "warning",
            });
        }).finally(() => {
            setSendingOTP(false);
        })
    }

    React.useEffect(() => {
        let timeout = null
        if (showTimer) {
            timeout = setTimeout(() => {
                setShowTimer(false)
            }, 60000);
        }
        return () => clearTimeout(timeout)
    }, [showTimer]);

    React.useEffect(() => {
        dispatch(fetchMyCard());
    }, []);

    const OTPButton = () => (
        <LoadingButton size='small' sx={{ mb: 1 }} type='button' variant='contained' onClick={getActivationOTP} loading={sendingOTP} disabled={showTimer || activationForm.values.digits.length !== 3}>{showTimer === false ? 'Get Code' : 'Try after a minute'}</LoadingButton>
    )

    const formatCardNumber = (number) => {
        const cardNumber = String(number).slice(0, 3).concat(' ').concat(String(number).slice(3, 6)).concat(' ').concat(String(number).slice(6, 9)).concat(' ').concat(String(number).slice(9, 12))
        return cardNumber
    }

    return (
        <Page title="Activate NFC Card">
            <Container maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={smUp ? 5 : 2}>
                    <Typography variant="h4" gutterBottom >
                        Activate NFC Card
                    </Typography>
                </Stack>
                {!showWaitDialog ? (<Grid container spacing={smUp ? 6 : 2} justifyContent={hasCard ? "space-around" : "space-between"}>
                    {(myCard?.request?.status !== "ACTIVATED" && myCard?.status !== "ACTIVE") ? (
                        <>

                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <Zoom in>
                                    <Card>
                                        {/* <CardContent> */}
                                        {/* <Stack> */}
                                        {/* <Typography variant="h6" alignSelf={'center'}>Please fill out the form</Typography> */}
                                        <Box sx={{
                                            boxShadow: 3,
                                            // borderRadius: 2,
                                            position: "relative",
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            textAlign: 'center',
                                            color: (theme) => theme.palette.primary.main,
                                            background: 'none',
                                            bgcolor: (theme) => !hasCard && theme.palette.secondary.lighter,
                                        }}>
                                            <CardMedia
                                                component="img"
                                                image={systemConfig && systemConfig.card_instruction_photo}
                                                alt="NFC Card"
                                                sx={(theme) => ({
                                                    maxWidth: "100%",
                                                    alignSelf: 'center',
                                                    // borderRadius: 2,
                                                    // mt:1,
                                                    border: 1,
                                                    // mb:1,
                                                    objectFit: "contain",
                                                    cursor: 'pointer',
                                                    "&.MuiCardMedia-img": {
                                                        borderRadius: 2,
                                                    },
                                                    // [theme.breakpoints.down('md')]: {
                                                    //     padding: "0.5em",
                                                    // },
                                                    [theme.breakpoints.up('md')]: {
                                                        maxWidth: "100%",
                                                    },
                                                })}
                                            />
                                            {/* <CardNumberStyle>
                                            {hasCard && myCard && formatCardNumber(myCard.card_number.slice(0,myCard.card_number.length-3).concat('***'))}
                                        </CardNumberStyle>
                                        {myCard && <OwnerNameStyle>{hasCard && String(myCard.user_name).toUpperCase()} </OwnerNameStyle>} */}
                                        </Box>
                                        {/* </Stack> */}

                                        {/* </CardContent> */}
                                    </Card>
                                </Zoom>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Zoom in>
                                    <Card>
                                        <CardContent>
                                            <Stack spacing={1}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    variant='standard'
                                                    id="digits"
                                                    name="digits"
                                                    type="text"
                                                    value={activationForm.values.digits}
                                                    onChange={activationForm.handleChange}
                                                    error={!!activationForm.errors.digits && activationForm.touched.digits}
                                                    helperText={'Enter last 3 digits of your card number'}
                                                    placeholder="type digits.."
                                                    label="Digits"
                                                    onKeyDown={onEnterKeyPress}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end">
                                                                <OTPButton />
                                                            </InputAdornment>
                                                    }}
                                                />
                                                {activationForm.values.digits && otpSent && <TextField
                                                    fullWidth
                                                    required
                                                    variant='standard'
                                                    id="otp"
                                                    name="otp"
                                                    type={'text'}
                                                    value={activationForm.values.otp}
                                                    onChange={activationForm.handleChange}
                                                    error={!!activationForm.errors.otp && activationForm.touched.otp}
                                                    helperText={'Enter 6 digit verification code'}
                                                    placeholder="Enter Code"
                                                    label="Verification Code"
                                                    onKeyDown={onEnterKeyPress}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => setShowOTP(!showOTP)} edge="end">
                                                                    <Iconify icon={showOTP ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />}
                                            </Stack>
                                            <LoadingButton sx={{ mt: 2 }} disabled={!activationForm.isValid || activationForm.isValidating} fullWidth size="medium" type="button" onClick={activationForm.handleSubmit} variant="contained" loading={activationForm.isSubmitting}>
                                                Activate
                                            </LoadingButton>
                                        </CardContent>
                                    </Card>
                                </Zoom>
                            </Grid>
                        </>) : (
                        <Typography>Your card is activated</Typography>
                    )}
                </Grid>) : (
                    <LoaderDialog />
                )}

            </Container>
        </Page>
    );
}

import * as React from 'react'
import ApplyCard from '../pages/myCards/ApplyCardv2';
import ActivateCardThreeDigits from '../pages/myCards/ActivateCardThreeDigits';
import MyCardDetail from '../pages/myCards/MyCardDetail';
import AddService from '../pages/myCards/AddService';
import MyReward from '../pages/reward/MyReward';
import ActivatePrePrintedCard from '../pages/myCards/ActivatePrePrintedCard';


// ----------------------------------------------------------------------
const MyNFCCard = React.lazy(()=>import('../pages/myCards/MyNFCCard'))
const MyCard = React.lazy(()=>import('../pages/myCards/MyCard'))
const HowTo = React.lazy(()=>import('../pages/how-to/HowTo'))

export const CustomerRoutes=[
    // customer routes
    {path: 'how-to', element: <HowTo/>,name:"How To",title:"Guideline"},
    {path: 'my-card', element: <MyCard/>,name:"My Cards",title:"My NFC Card"},
    {path: 'my-card/detail', element: <MyCardDetail/>,name:"My NFC Card detail",title:"My NFC Card detail"},
    {path: 'my-card/apply', element: <ApplyCard/>,name:"Apply NFC Card",title:"Apply for NFC Card"},
    {path: 'my-card/activate', element: <ActivateCardThreeDigits/>,name:"Activate NFC Card",title:"Activate NFC Card"},
    {path: 'my-card/activate-pre-printed-card', element: <ActivatePrePrintedCard/>,name:"Activate Pre Printed NFC Card",title:"Activate Pre Printed NFC Card"},
    {path: 'my-card/add-service', element: <AddService/>,name:"Add Service",title:"Add Service"},
    {path: 'my-reward', element: <MyReward/>,name:"My Reward",title:"My Reward"}
]